@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

body,
html {
  min-height: 100vh !important;
}

.cara-left {
  top: 0.86rem;
  left: -0.86rem;
}

.cara-right {
  top: 0.86rem;
  right: -0.86rem;
}

.gray-gradient {
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.w-test {
  width: 100%;
}

@media (max-width: 1024px) {
  .w-test {
    width: 56rem;
  }
}
